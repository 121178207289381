import Link from "next/link";
import PanelItem from "./PanelItem";
import * as React from "react";
import Image from "next/image";
import styles from "./styles.module.css";
import { SVGIconBroadband, SVGIconMobile, SVGIconThunderbolt } from "./Icons";

const SuperEasy: React.FC<{}> = () => {
	return (
		<section className={`${styles.section}`}>
			<div className={`${styles.panel__section} container`}>
				<div className="row">
					<div className=" d-flex align-items-center">
						<div className={` text-center mx-auto my-5`}>
							<h2 className={`${styles.headingText} mt-md-5  mb-4`}>
								OUR MISSION
							</h2>
							<div className={`${styles.heading} mx-auto	mb-3`}>
								{`Getting great deals should be simple and hassle-free.`}
							</div>
							<div className={`${styles.heading} mx-auto	mb-3`}>
								{`At Consumer Choices, our mission is to simplify finding deals for consumer products and services, including Broadband. We cut through the endless jargon and offers to provide an impartial service.`}
							</div>
							<div className={`${styles.heading} mx-auto	`}>
								{`Our knowledgeable team takes the time to understand your needs, helping you make informed choices, get a great deal and save money.`}
							</div>
						</div>
					</div>
				</div>
				<div className={`${styles.panel__section}`}>
					<div className={`${styles.panel__section__background} mx-auto row`}>
						<PanelItem
							icon={SVGIconBroadband}
							heading="BROADBAND"
							text="We take into consideration your physical location and internet requirements, so we can offer you the best value service, for you and your family."
							link="/broadband"
							linkText="See All Broadband Deals"
						/>
						<PanelItem
							icon={SVGIconMobile}
							heading="MOBILE"
							text="With so many options, how do you know the best plan for you when it comes to mobile service providers?"
							link="/mobile"
							linkText="See All Mobile Deals"
						/>
						<PanelItem
							icon={SVGIconThunderbolt}
							heading="ENERGY"
							text="The high costs of energy have impacted everyone in the UK. So we’re working on getting you an offer that will help to ease the financial burden. "
							link="/energy"
							linkText="Get Energy Quotes"
						/>
					</div>
				</div>
				<div className={`d-flex justify-content-center`}>
					<Link href="https://uk.trustpilot.com/review/consumer-choices.co.uk">
						<Image
							src={"/logos/trustpilot-logo.png"}
							alt={"header image"}
							height={28}
							width={120}
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default SuperEasy;
