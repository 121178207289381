import Link from "next/link";
import * as React from "react";
import Image from "next/image";
import Nav from "@/components/Nav";
import styles from "../styles.module.css";
import { Heading } from "@/components/Shared";

const LandingHeader: React.FC<{}> = () => {
	return (
		<header>
			<div className={`${styles.header}`}>
				<Nav />

				<div className={`container mx-auto pt-3`}>
					<div className="row">
						<div className="col-lg-6 text-center py-md-5 py-0 pt-5 pt-md-0 d-flex flex-column justify-content-center">
							<h1 className={`${styles.heading}`}>
								WE UNDERSTAND THE JARGON, SO YOU DON’T HAVE TO.
							</h1>

							<div className="py-3 w-75 mx-auto">
								<Heading
									title={
										"Finding the best broadband deal takes time, which is why we do it for you."
									}
									isBold={false}
									variant={5}
								/>
							</div>
							<div className={`py-3 mx-auto ${styles.button}`}>
								<Link href="/broadband " className={` ${styles.buttonStyle} `}>
									<button
										className={`button  w-100 `}
										name={"broadband-deals"}
										type={"button"}
									>
										Get Broadband Deals
									</button>
								</Link>
							</div>
							<div className="py-3 mx-auto">
								<Link href="https://uk.trustpilot.com/review/consumer-choices.co.uk">
									<Image
										src={"/logos/trustpilot-logo.png"}
										alt={"header image"}
										height={25}
										width={100}
									/>
								</Link>
							</div>
						</div>

						<div className="col-lg-6 ">
							<div className={`${styles.image_container} mx-auto `}>
								<Image
									src={"/home-header-circular-image.webp"}
									alt={"header image"}
									layout="responsive"
									objectFit="contain"
									width={680}
									height={600}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default LandingHeader;
