import axios from "axios";
import { Address } from "@/types/index";
import { ApiResponse } from "@/types/api";

export const getAddresses = async (
	postcode: string
): Promise<ApiResponse<Address[]>> => {
	try {
		const response = await axios({
			url: `/api/addresses?postcode=${postcode}`,
			method: "GET",
		});

		return {
			data: response.data.addresses,
			error: null,
		};
	} catch (e: any) {
		return {
			data: [],
			error: {
				message: "Unable to retrieve addresses.",
			},
		};
	}
};
