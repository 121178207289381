import * as Yup from "yup";
import * as React from "react";
import { useFormik } from "formik";
import { Error } from "@/types/index";
import { createMailingListEntry } from "@/api/mailingList";

const useSubscribeMailingList = () => {
	const [loading, setLoading] = React.useState<Boolean>(false);
	const [error, setError] = React.useState<Error | null>(null);
	const [submitted, setSubmitted] = React.useState<Boolean>(false);

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Please enter a valid email address.")
				.required("Please enter your email address."),
		}),
		onSubmit: async (values: any) => {
			setLoading(true);
			setError(null);

			try {
				const response = await createMailingListEntry(values.email);
				setLoading(false);
				setSubmitted(true);
			} catch (e: any) {
				setLoading(false);
				setError(e.response.data);
			}
		},
	});

	return {
		loading,
		error,
		submitted,
		formik,
	};
};

export default useSubscribeMailingList;
