import * as React from "react";
import Image from "next/image";
import styles from "./styles.module.css";
import { Heading } from "@/components/Shared";

const items = [
	{
		src: "icons/Talk-to-Experts.svg",
		alt: "Talk to Experts",
		title: "Talk to Experts",
		text: "Get expert advice based on your needs",
	},
	{
		src: "/icons/Great-deals.svg",
		alt: "Great deals",
		title: "Great deals",
		text: "You can rely on us to cut through the noise",
	},
	{
		src: "/icons/save-time.svg",
		alt: "Save time",
		title: "Save time",
		text: "We do the work, so you don’t have to",
	},
];

const WhyChooseUs: React.FC = () => {
	return (
		<section className={`${styles.panel__section__background}`}>
			<div className={`my-3 container`}>
				<div className="row">
					<div className="d-flex align-items-center">
						<div className={`text-center mx-auto`}>
							<h2 className={`${styles.headingText} mt-5 mb-4`}>
								WHY CHOOSE US?
							</h2>
							<div className={`${styles.heading} mx-auto mb-3`}>
								Because we have helped hundreds of thousands of customers find
								great deals.
							</div>
						</div>
					</div>
				</div>
				<div className={`${styles.panel__section}`}>
					<div className={`mx-auto row`}>
						{items.map((item) => (
							<div
								key={item.src}
								className={`col-md-4 col-12 ${styles.panel__hoverEffect}`}
							>
								<div className={`h-100 p-3 text-center mt-4 mb-4 `}>
									<div className={`${styles.panel__icon} mb-4 `}>
										<Image
											src={item.src}
											alt={item.alt}
											width="78"
											height="78"
										/>
									</div>
									<Heading variant={4} isBold={true} title={item.title} />
									<p className={styles.panel__text}>{item.text}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyChooseUs;
