import Link from "next/link";
import * as React from "react";
import Image from "next/image";
import { Checkbox, Heading } from "../Shared";
import styles from "./styles.module.css";
import { Nav } from "@/types/nav";
import useSubscribeMailingList from "@/hooks/useSubscribeMailingList";

export type FooterItem = {
	title: string;
	href: string;
};

export type FooterSubMenu = {
	[key: string]: FooterItem[];
};

export type FooterType = FooterSubMenu[];

const links: Nav = [
	{
		title: "Home",
		href: "/",
		subMenu: [],
	},
	{
		title: "Contact Us",
		href: "/contact-us",
		subMenu: [],
	},
	{
		title: "Broadband",
		href: "/broadband",
		subMenu: [],
	},

	{
		title: "Mobile",
		href: "/mobile",
		subMenu: [],
	},
	{
		title: "Terms & Conditions",
		href: "/legal/terms-of-service",
		subMenu: [],
	},
	{
		title: "Privacy Policy",
		href: "/legal/privacy-policy",
		subMenu: [],
	},
];

const Footer: React.FC = () => {
	const listItems = links.map((item: any) => {
		return (
			<li key={item.title}>
				<span className="text-white">
					<Link href={item.href}>{item.title}</Link>
				</span>
			</li>
		);
	});

	const { loading, error, submitted, formik } = useSubscribeMailingList();

	return (
		<footer className={`${styles.footer} py-4`}>
			<div className="container">
				<div className={` row pb-4`}>
					<div className="col-12 col-md-6">
						<Image
							src="/logos/cc-logo.png"
							alt="logo"
							layout="responsive"
							width={400}
							height={113}
						/>
					</div>

					<div
						className={`${styles.list} col-lg-2 col-md-4 col-sm-12 mt-4 mx-auto`}
					>
						<div className="pb-3 text-center text-md-start pt-3 pt-md-0">
							<Heading title={"QUICK LINKS"} variant={2} isBold={true} />
						</div>
						<ul className="list-unstyled text-center text-md-start ">
							{listItems}
						</ul>

						<div className="row  pt-4">
							<div className="col text-center text-md-start ">
								<a
									href="https://www.facebook.com/Savely.co.uk/"
									target="_blank"
									rel="noreferrer"
									className={styles.social}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="23"
										height="23"
										fill="currentColor"
										className="bi bi-facebook"
										viewBox="0 0 16 16"
									>
										<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
									</svg>
								</a>
							</div>
							<div className="col text-center text-md-start">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="23"
									height="23"
									fill="currentColor"
									className="bi bi-linkedin"
									viewBox="0 0 16 16"
								>
									<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
								</svg>
							</div>
							<div className="col text-center text-md-start">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="23"
									height="23"
									fill="currentColor"
									className="bi bi-twitter"
									viewBox="0 0 16 16"
								>
									<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
								</svg>
							</div>
						</div>
					</div>
					{/* <div className="col-lg-4 col-md-12 mt-4 mb-4 pt-3 pt-md-0">
						<div className="pb-3 text-center text-md-start">
							<Heading
								title={"SIGN UP TO OUR NEWSLETTER"}
								variant={2}
								isBold={true}
							/>
						</div>
						<p className={`row ms-1 ${styles.text}`}>
							Get the latest tips, advice and offers from our team of experts.
						</p>
						<input
							type="text"
							className={`row ms-1 ${styles.input_field} mt-4`}
							placeholder="First Name"
						/>

						<input
							className={`row ms-1 ${styles.input_field} mt-4`}
							name="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							placeholder="Email..."
						/>
						<div className="row mt-4 ms-1">
							<input
								type="checkbox"
								className={`col-1 p-0 ${styles.checkbox_box}`}
							/>
							<p className={`col-11 p-0 ${styles.checkbox_text}`}>
								I consent to be contacted by email for the Consumer Choices
								newsletter and to hear about the latest deals and offers.
							</p>
							<p className={`col-11 mt-2 ${styles.checkbox_text}`}>
								This site is protected by reCAPTCHA and the Google Privacy
								Policy and Terms of Service apply.
							</p>

							<button
								className={`${styles["broadband-affiliate__button"]} mt-2 mb-2 button d-flex align-items-center text-decoration-none w-25`}
								name="subscribe"
								type="submit"
							>
								{loading ? (
									<>
										<span
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
										<span className="visually-hidden">Loading...</span>
									</>
								) : (
									<h5 className="m-auto fw-bold">SUBMIT</h5>
								)}
							</button>
							<p className={`col-11 mt-3 ${styles.checkbox_text}`}>
								By clicking submit you agree to the Terms & Conditions and the
								Privacy Policy.
							</p>
						</div>
					</div> */}
				</div>
				<div className="row mt-4">
					<div className="col text-center">
						<p className={styles.legal__text}>
							{" "}
							Consumer Choices is a trading style of Savely Financial Services
							Limited (14649692). Charlotte House, 500 Charlotte Road, Sheffield
							S2 4ER.
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
