import Link from "next/link";
import * as React from "react";
import styles from "../styles.module.css";
import { Heading } from "@/components/Shared";

const PanelItem: React.FC<{
	icon: React.ComponentType;
	heading: string;
	text: string;
	link: string;
	linkText: string;
}> = ({ icon: Icon, heading, text, link, linkText }) => {
	return (
		<div className={`col-md-4 col-12 ${styles.panel__hoverEffect}`}>
			<div className={`h-100 p-3 text-center mt-4 mb-4 `}>
				<div className={`${styles.panel__icon} mb-4 `}>
					<Icon />
				</div>
				<Heading variant={4} isBold={true} title={heading} />
				<p className={styles.panel__text}>{text}</p>
				<Link href={link} className={`${styles.getQuotesLink}`}>
					{linkText}
				</Link>
			</div>
		</div>
	);
};

export default PanelItem;
