import { Error } from "@/types/api";

export * from "@/api/leads";
export * from "@/api/addresses";

export const handleApiError = (error: any): Error => {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		return error.response.data;
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		return {
			message: "An unknown error has occurred.",
		};
	} else {
		// Something happened in setting up the request that triggered an Error
		return {
			message: "An unknown error has occurred.",
		};
	}
};
