import Link from "next/link";
import * as React from "react";
import styles from "./styles.module.css";

const BookACall: React.FC = () => {
	return (
		<section className={styles.mailing_list}>
			<div className="container text-center col-sm-10 col-md-6 col-lg-6 col-xl-6">
				<div className="row d-flex justify-content-center">
					<h2 className={`${styles.headingText}  `}>
						WE’RE READY TO FIND THE BEST BROADBAND DEAL FOR YOU.
					</h2>

					<Link
						href="/journey/broadband"
						className={`${styles.buttonStyle} pt-3 px-0 `}
					>
						<button
							className={`button ${styles.buttonStyle}  `}
							name={"broadband-deals"}
							type={"button"}
						>
							Book a call
						</button>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default BookACall;
