import Link from "next/link";
import Image from "next/image";
import styles from "./styles.module.css";

const TrustPilot: React.FC<{}> = ({}) => {
	return (
		<section className="pt-md-5 pt-0 pb-5">
			<div className="container  pt-0 pb-5">
				<div className="row">
					<div className="col-lg-6 ">
						<div className={`${styles.image_container}  `}>
							<Image
								src={"/home-girl-image-1.webp"}
								alt={"header image"}
								layout="responsive"
								objectFit="contain"
								width={519}
								height={519}
							/>
						</div>
					</div>
					<div className="col-lg-6 py-md-5 py-0 pt-md-5 pt-0 d-flex flex-column ">
						<h2 className={`${styles.heading} `}>
							PROFESSIONAL CUSTOMER SERVICE IS THE BACKBONE OF WHAT WE DO
						</h2>

						<div className={`${styles.text} mt-2 mb-5`}>
							{`Getting great deals should be simple and hassle-free.`}
						</div>
						<div className={`${styles.text} mb-5`}>
							{`At Consumer Choices, our mission is to simplify finding deals for consumer products and services, including Broadband. We cut through the endless jargon and offers to provide an impartial service.`}
						</div>
						<div className={`${styles.text} mb-5 `}>
							{`Our knowledgeable team takes the time to understand your needs, helping you make informed choices, get a great deal and save money.`}
						</div>
						{/* <div className={` ${styles.button}`}>
							<button
								className={`button w-100 `}
								name={"broadband-deals"}
								type={"button"}
							>
								Meet the Team
							</button>
						</div> */}
					</div>
				</div>
				<div className="container py-5 text-center d-flex flex-column">
					<h2 className={`${styles.heading} mx-auto`}>
						WHAT OUR CUSTOMERS THINK
					</h2>

					<div className={`${styles.text} mt-2 mb-md-5 mb-0 mx-auto`}>
						{`What you say matters. We are constantly improving and continue to produce the best results to give you the best service.`}
					</div>
				</div>
				<div className={`d-flex justify-content-center`}>
					<Link href="https://uk.trustpilot.com/review/consumer-choices.co.uk">
						<Image
							src={"/logos/trustpilot.svg"}
							alt={"header image"}
							height={50}
							width={200}
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default TrustPilot;
