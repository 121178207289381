import { LANDING_PAGE_PROVIDERS } from "@/data/consts";
import * as React from "react";
import Footer from "@/components/Footer";
import { getOne } from "@/api/contentful";
import { Providers } from "@/types/providers";
import SuperEasy from "@/components/SuperEasy";
import TrustPilot from "@/components/TrustPilot";
import PageSEO from "@/components/Shared/PageSEO";
import LandingHeader from "@/components/Header/LandingHeader";
import WeWorkWith from "@/components/WeWorkWith";
import BookACall from "@/components/BookACall";
import WhyChooseUs from "@/components/WhyChooseUs";

const CompareBroadband: React.FC<{
	providers: {
		fields: {
			providerLogos: Array<Providers>;
		};
	};
}> = ({ providers }) => {
	return (
		<div className="h-100 d-flex flex-column">
			<PageSEO
				title={
					"Compare Amazing Broadband, Energy and Mobile Deals | Consumer Choices"
				}
				description={
					"Compare great Broadband, Mobile and Energy deals from some of the UK's major providers and more. Get yourself a better deal today and start saving with Consumer Choices."
				}
				keywords={
					"Consumer Choices, Consumer Choices Broadband, Consumer Choices Energy, Consumer Choices Mobile"
				}
			/>
			<LandingHeader />

			<SuperEasy />
			<BookACall />
			<TrustPilot />
			<WhyChooseUs />
			<WeWorkWith providers={providers.fields.providerLogos} />
			<Footer />
		</div>
	);
};

export async function getStaticProps() {
	const providers = await getOne(LANDING_PAGE_PROVIDERS);

	return {
		props: {
			providers,
		},
		revalidate: Number(process.env.REVALIDATE_CONTENTFUL),
	};
}

export default CompareBroadband;
