import styles from "../styles.module.css";

export const SVGIconBroadband = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="60"
		height="60"
		viewBox="0 0 60 60"
	>
		<g transform="translate(-254 -1454)">
			<g id="network" transform="translate(258.562 1457.737)">
				<path
					className={`${styles.svg__path}`}
					d="M62.41,54.952a6.534,6.534,0,0,0-1.216,12.954V86.741a1.216,1.216,0,1,0,2.432,0V67.906A6.534,6.534,0,0,0,62.41,54.952Z"
					transform="translate(-36.688 -35.766)"
					fill="#c1d12b"
				></path>
				<path
					className={`${styles.svg__path}`}
					d="M60.214,37.345A15.518,15.518,0,0,0,38.267,59.291a1.216,1.216,0,0,0,1.719-1.719,13.087,13.087,0,1,1,18.508,0,1.216,1.216,0,1,0,1.719,1.719,15.536,15.536,0,0,0,0-21.946Z"
					transform="translate(-23.518 -22.596)"
					fill="#c1d12b"
				></path>{" "}
				<path
					className={`${styles.svg__path}`}
					d="M56.316,26.191a20.65,20.65,0,0,0-29.2,29.2,1.216,1.216,0,1,0,1.719-1.719,18.219,18.219,0,1,1,25.765,0,1.216,1.216,0,1,0,1.719,1.719,20.674,20.674,0,0,0,0-29.2Z"
					transform="translate(-15.992 -15.071)"
					fill="#c1d12b"
				></path>{" "}
				<path
					className={`${styles.svg__path}`}
					d="M16.062,15.14a25.762,25.762,0,0,0,0,36.393,1.216,1.216,0,1,0,1.719-1.719,23.3,23.3,0,1,1,32.955,0,1.216,1.216,0,1,0,1.719,1.719A25.734,25.734,0,0,0,16.062,15.14Z"
					transform="translate(-8.536 -7.615)"
					fill="#c1d12b"
				></path>
			</g>
		</g>
	</svg>
);

// SVGIconMobile.js
export const SVGIconMobile = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="66"
		height="66"
		viewBox="0 0 66 66"
	>
		<g transform="translate(-530 -1454)">
			<g transform="translate(435.114 1458.578)">
				<path
					className={`${styles.svg__path}`}
					d="M252.361,35.6h-4.167a.833.833,0,0,0,0,1.666h4.167a.833.833,0,1,0,0-1.666Z"
					transform="translate(-247.361 -35.599)"
					fill="#c1d11e"
				></path>
				<g transform="translate(123.012 3.952)">
					<path
						className={`${styles.svg__path}`}
						d="M214.428,35.6h-1.494a.833.833,0,1,0,0,1.666h1.494a.833.833,0,1,0,0-1.666Z"
						transform="translate(-212.101 -35.599)"
						fill="#c1d11e"
					></path>
				</g>
				<g transform="translate(124.652 47.679)">
					<path
						className={`${styles.svg__path}`}
						d="M230.108,429.451a3.234,3.234,0,1,0,3.234,3.234A3.237,3.237,0,0,0,230.108,429.451Zm0,4.8a1.568,1.568,0,1,1,1.568-1.568A1.57,1.57,0,0,1,230.108,434.252Z"
						transform="translate(-226.874 -429.451)"
						fill="#c1d11e"
					></path>
				</g>
				<g transform="translate(115.446 7.762)">
					<path
						className={`${styles.svg__path}`}
						d="M168,69.913H144.788a.833.833,0,0,0-.833.833V99.213a.833.833,0,1,0,1.666,0V71.579h21.548V88.032a.833.833,0,0,0,1.666,0V70.746A.833.833,0,0,0,168,69.913Z"
						transform="translate(-143.955 -69.913)"
						fill="#c1d11e"
					></path>
				</g>
				<g transform="translate(115.446 28.38)">
					<path
						className={`${styles.svg__path}`}
						d="M168,255.622a.833.833,0,0,0-.833.833v15.821H145.621v-4.64a.833.833,0,0,0-1.666,0v5.473a.833.833,0,0,0,.833.833H168a.833.833,0,0,0,.833-.833V256.455A.833.833,0,0,0,168,255.622Z"
						transform="translate(-143.955 -255.622)"
						fill="#c1d11e"
					></path>
				</g>
				<g transform="translate(111.886)">
					<path
						className={`${styles.svg__path}`}
						d="M139.176,0H116.6a4.716,4.716,0,0,0-4.71,4.71v8.914a.833.833,0,0,0,1.666,0V4.71A3.048,3.048,0,0,1,116.6,1.666h22.58A3.048,3.048,0,0,1,142.22,4.71V52.134a3.048,3.048,0,0,1-3.044,3.044H116.6a3.048,3.048,0,0,1-3.044-3.044V16.956a.833.833,0,1,0-1.666,0V52.134a4.716,4.716,0,0,0,4.71,4.71h22.58a4.715,4.715,0,0,0,4.71-4.71V4.71A4.716,4.716,0,0,0,139.176,0Z"
						transform="translate(-111.886)"
						fill="#c1d11e"
					></path>
				</g>
				<g transform="translate(119.194 19.294)">
					<path
						className={`${styles.svg__path}`}
						d="M194.959,174.574a2.689,2.689,0,0,0-3.8,0l-6.54,6.54-2.319-2.32a2.689,2.689,0,0,0-3.8,3.8l4.221,4.221a2.689,2.689,0,0,0,3.8,0l8.441-8.441a2.689,2.689,0,0,0,0-3.8Zm-1.178,2.624-8.441,8.441a1.023,1.023,0,0,1-1.446,0l-4.22-4.22a1.023,1.023,0,1,1,1.446-1.446l2.908,2.908a.833.833,0,0,0,1.178,0l7.129-7.129a1.023,1.023,0,1,1,1.446,1.446Z"
						transform="translate(-177.708 -173.787)"
						fill="#c1d11e"
					></path>
				</g>
			</g>
			<rect
				width="66"
				height="66"
				transform="translate(530 1454)"
				fill="none"
			></rect>
		</g>
	</svg>
);

// SVGIconThunderbolt.js
export const SVGIconThunderbolt = () => (
	<svg
		id="thunderbolt"
		xmlns="http://www.w3.org/2000/svg"
		width="66.003"
		height="66.003"
		viewBox="0 0 66.003 66.003"
	>
		<path
			className={`${styles.svg__path}`}
			d="M12.875,8.25A1.376,1.376,0,0,1,11.5,6.875v-5.5a1.375,1.375,0,0,1,2.75,0v5.5A1.376,1.376,0,0,1,12.875,8.25Z"
			transform="translate(20.127)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M19.594,10.006A1.374,1.374,0,0,1,18.62,7.66l3.889-3.889a1.375,1.375,0,0,1,1.944,1.944L20.564,9.6A1.37,1.37,0,0,1,19.594,10.006Z"
			transform="translate(31.884 5.896)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M27.875,14.25h-5.5a1.375,1.375,0,0,1,0-2.75h5.5a1.375,1.375,0,1,1,0,2.75Z"
			transform="translate(36.753 20.127)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M23.482,24.856a1.381,1.381,0,0,1-.974-.4L18.62,20.565a1.375,1.375,0,0,1,1.944-1.944l3.889,3.889a1.374,1.374,0,0,1-.971,2.346Z"
			transform="translate(31.884 31.885)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M12.875,29.25A1.376,1.376,0,0,1,11.5,27.875v-5.5a1.375,1.375,0,0,1,2.75,0v5.5A1.376,1.376,0,0,1,12.875,29.25Z"
			transform="translate(20.127 36.753)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M4.744,24.856A1.374,1.374,0,0,1,3.77,22.51l3.889-3.889A1.375,1.375,0,0,1,9.6,20.565L5.714,24.454a1.37,1.37,0,0,1-.971.4Z"
			transform="translate(5.894 31.885)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M6.875,14.25h-5.5a1.375,1.375,0,0,1,0-2.75h5.5a1.375,1.375,0,0,1,0,2.75Z"
			transform="translate(0 20.127)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M8.63,10.006a1.381,1.381,0,0,1-.974-.4L3.771,5.715A1.375,1.375,0,0,1,5.715,3.771L9.6,7.66a1.374,1.374,0,0,1-.974,2.346Z"
			transform="translate(5.896 5.896)"
			fill="#c1d11e"
		></path>
		<path
			className={`${styles.svg__path}`}
			d="M16.826,48a1.4,1.4,0,0,1-.481-.085,1.375,1.375,0,0,1-.883-1.469l2.346-17.7H8.375a1.375,1.375,0,0,1-1.1-2.2l16.5-22A1.376,1.376,0,0,1,26.235,5.6L23.749,20.5h9.378a1.373,1.373,0,0,1,1.21.723,1.388,1.388,0,0,1-.06,1.411l-16.3,24.751a1.381,1.381,0,0,1-1.15.616Zm-5.7-22h8.25A1.379,1.379,0,0,1,20.74,27.56L18.977,40.865l11.6-17.612H22.126a1.373,1.373,0,0,1-1.356-1.6l1.823-10.94Z"
			transform="translate(12.251 6.999)"
			fill="#c1d11e"
		></path>
	</svg>
);
