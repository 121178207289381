import axios from "axios";
import { ApiResponse } from "@/types/index";
import { handleApiError } from "@/utils/formatApiError";

export type MailingList = {
	uuid: string;
	email: string;
};

export const createMailingListEntry = async (
	email: string
): Promise<ApiResponse<MailingList | null>> => {
	try {
		const response = await axios({
			url: `/api/mailing-list`,
			method: "POST",
			data: { email },
		});

		return {
			data: response.data,
			error: null,
		};
	} catch (e: any) {
		return {
			data: null,
			error: handleApiError(e),
		};
	}
};
